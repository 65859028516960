import React, { useState, useEffect, useRef } from 'react'
import { useApi } from '../../hooks/useApi'
import { useTrans } from '../../hooks/useTrans'
import {
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CListGroup,
  CListGroupItem,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilSearch } from '@coreui/icons'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const AutocompleteSearch = () => {
  const api = useApi()
  const { setIsLoading } = useTrans()
  const [items, setItems] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const { i18n, t } = useTranslation()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const navigate = useNavigate()
  const inputRef = useRef(null)
  const listRef = useRef(null)
  const [activeTerm, setActiveTerm] = useState(-1)

  useEffect(() => {
    setIsLoading(true)
    Promise.all([
      api.get(api.endpoint.ACCOUNT_LIST).then((data) => {
        setItems(
          data.items.map((it) => {
            return { id: it.id, fio: it.fio }
          }),
        )
      }),
    ]).finally(() => setIsLoading(false))
  }, [])

  useEffect(() => {
    setSuggestions(
      items
        .filter((item) => item.fio.toLowerCase().startsWith(searchTerm.toLowerCase()))
        .slice(0, 10),
    )
  }, [searchTerm])

  const handleChange = (e) => {
    const { value } = e.target
    setSearchTerm(value)
    setIsDropdownOpen(true)
  }

  const handleSelect = (suggestion) => {
    setSuggestions([])
    setIsDropdownOpen(false)
    navigate(`/user/${suggestion.id}/edit`, { replace: true })
    setSearchTerm('')
  }

  const handleBlur = () => {
    listRef.current?.classList.add('opacity-0')
    setTimeout(() => {
      setSearchTerm('')
      setIsDropdownOpen(false)
    }, 1000)
  }

  const handleKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault()
        setActiveTerm((prevIndex) => Math.max(0, prevIndex - 1))
        break
      case 'ArrowDown':
        e.preventDefault()
        setActiveTerm((prevIndex) => Math.min(suggestions.length - 1, prevIndex + 1))
        break
      case 'Enter':
        e.preventDefault()
        if (suggestions[activeTerm]?.fio) {
          handleSelect(suggestions[activeTerm])
        } else if (suggestions.length === 1) {
          handleSelect(suggestions[0])
        }
        setActiveTerm(-1)
        break
    }
  }

  return (
    <div className="d-none d-sm-flex">
      <CInputGroup className="header-search-group">
        <CInputGroupText id="search-addon" className="bg-body-secondary border-0 px-1">
          <CIcon icon={cilSearch} size="lg" className="my-1 mx-2 text-body-secondary" />
        </CInputGroupText>
        <CFormInput
          ref={inputRef}
          placeholder={t('search')}
          aria-label="Search"
          aria-describedby="search-addon"
          className="bg-body-secondary border-0 position-relative dropdown-toggle"
          value={searchTerm}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        />
        {isDropdownOpen && suggestions.length > 0 && searchTerm.length > 0 && (
          <CListGroup
            ref={listRef}
            className="d-none d-sm-flex dropdown-menu top-100 min-w-100 rounded-bottom"
            flush
          >
            {suggestions.map((suggestion, index) => (
              <CListGroupItem
                className={
                  index === activeTerm ? 'dropdown-item dropdown-item-focus' : 'dropdown-item'
                }
                key={index}
                onClick={() => handleSelect(suggestion)}
                role="button"
              >
                {suggestion.fio}
              </CListGroupItem>
            ))}
          </CListGroup>
        )}
      </CInputGroup>
    </div>
  )
}

export default AutocompleteSearch
